<template>
  <div class="handshake">
    <div class="condition-box">
      <el-row style="padding: 0 30px">
        <el-col :span="8">
          <el-select
            v-model="jobId"
            placeholder="全部职位"
            @change="selectStatus"
            style="width: 130px; margin-right: 50px"
          >
            <el-option
              v-for="item2 in positionoptions"
              :key="item2.value"
              :label="item2.label"
              :value="item2.value"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
    </div>

    <!-- 数据列表 -->
    <div class="listbox" :style="{ 'min-height': sheight + 'px' }">
      <!-- 无数据 -->
      <div class="nodata" v-if="userList !=null && userList.length == 0">
        <img src="../../assets/images/person/p-nodata.png" />
        <div class="font16">当前无数据</div>
      </div>
      <div class="list" v-for="(itemes, index1) in userList" :key="index1">
        <div class="list-content">
          <div class="person-info">
            <div class="p-picurl">
              <img
                :src="'https://freemen.work/user/file/view/' + itemes.avatar"
              />
            </div>
            <div class="p-news">
              <div class="pn-name">
                <div class="namemain">
                  <span class="font22">{{ itemes.displayName }}</span>
                  <img
                    src="../../assets/images/person/boy.png"
                    v-if="itemes.sex == 1"
                  />
                  <img src="../../assets/images/person/girl.png" v-else />
                  <span class="font14">Free</span>
                </div>
                <div class="score font14">
                  <span>{{ itemes.score }}</span>
                </div>
              </div>
              <div class="beizhu font14">
                <div class="">
                  {{ itemes.expYear }}年 |
                  <!-- {{itemes.educationList.length ? itemes.educationList[itemes.educationList.length-1].educationType.itemText : '/'}} | {{new Date().getYear() - new Date(itemes.birthday).getYear()}}岁  -->
                  {{
                    new Date().getYear() -
                    new Date(itemes.birthday).getYear() -
                    (new Date().getMonth() >
                    new Date(itemes.birthday).getMonth()
                      ? 0
                      : 1)
                  }}岁 | {{ itemes.jobLevelNames }}
                </div>
                <div class="">
                  期望工作时间：{{
                    itemes.startDate ? itemes.startDate.split(" ")[0] : "/"
                  }}
                  —
                  {{ itemes.endDate ? itemes.endDate.split(" ")[0] : "/" }}
                </div>
              </div>
            </div>
          </div>
          <div class="transitionbox" v-if="itemes.userWorkExpList.length > 0">
            <!-- 3个 -->
            <template v-if="itemes.userWorkExpList.length < 4">
              <div
                class="t-one font14"
                v-for="(item, index) in itemes.userWorkExpList"
                :key="index"
              >
                <img src="../../assets/images/person/gangwei.png" />
                <span @click.stop="getMoreWork(itemes)">
                  {{ item.companyName }} ·
                  {{ item.jobName }}
                </span>
              </div>
            </template>
            <!-- 大于3 -->
            <template v-if="itemes.userWorkExpList.length > 3">
              <div class="t-one font14">
                <img src="../../assets/images/person/gangwei.png" />
                <span @click.stop="getMoreWork(itemes)">
                  {{ itemes.userWorkExpList[0].companyName }} ·
                  {{ itemes.userWorkExpList[0].jobName }}
                </span>
                <i
                  :class="
                    itemes.moreShow ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
                  "
                  @click.stop="getMoreWork(itemes)"
                ></i>
              </div>
            </template>
            <!-- 大于3 -->
            <el-collapse-transition v-if="itemes.userWorkExpList.length > 3">
              <div v-show="itemes.moreShow">
                <div
                  class="t-one font14"
                  v-show="index > 0"
                  v-for="(item, index) in itemes.userWorkExpList"
                  :key="index"
                >
                  <img src="../../assets/images/person/gangwei.png" />
                  <span>{{ item.companyName }} · {{ item.jobName }}</span>
                </div>
              </div>
            </el-collapse-transition>
          </div>
          <div class="label-info">
            <div class="li-left font14">
              <span
                v-show="index < 5"
                v-for="(item, index) in itemes.skillIds"
                :key="index"
                >{{ item.itemText }}</span
              >
            </div>
            <div class="li-right">
              <el-button class="yy" type="primary" @click="pass(itemes)"
                >取消不合适</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <div class="nextpage" @click="getMoreList" v-if="moreBtn">
        点击加载更多
      </div>
    </div>
    <!-- 分页 -->
    <!-- <div class="paginate" style="position: relative;">
			<el-pagination
				@current-change="handleCurrentChange"
				:current-page="pageInfo.pageNum"
				:page-size="pageInfo.pageSize"
				layout="total, prev, pager, next, jumper"
				:total="pageInfo.count"
			></el-pagination>
		</div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      sheight: "",
      formInline: {
        // keyword: '',
        // type: '',
        stuts: "",
        pageNum: 1,
        pageSize: 10,
      },
      pageInfo: {
        pageNo: 1,
        pageSize: 10,
        status: 2,
      },
      jobId: "",
      moreBtn: false,
      // 全部状态
      positionoptions: [],
      deleteDialogVisible: false,
      updownDialogVisible: false,

      userList: null,
      // activeNames: ['1'],
      show3: true,
    };
  },
  activated() {
    this.sheight = document.documentElement.clientHeight - 380;
    this.getJobList();
    this.getHandList();
  },
  mounted() {},
  methods: {
    getJobList() {
      //获取职位列表
      this.$api.joblist("get").then((res) => {
        this.positionoptions = [];
        res.data.map((item) => {
          if (item.status == 1) {
            let obj = {
              label: item.jobName,
              value: item.id,
            };
            this.positionoptions.push(obj);
          }
        });
      });
    },
    getHandList() {
      //获取数据列表
      this.$api.getHangList("get", this.pageInfo).then((res) => {
        if (this.jobId) {
          let arr = [];
          res.data.map((item) => {
            if (item.companyJobId == this.jobId) {
              arr.push(item);
            }
          });
          // this.userList = this.userList.concat(arr);
          if (this.pageInfo.pageNo == 1) {
            this.userList = res.data;
          } else {
            this.userList = this.userList.concat(arr);
          }
        } else {
          // this.userList = this.userList.concat(res.data);
          if (this.pageInfo.pageNo == 1) {
            this.userList = res.data;
          } else {
            this.userList = this.userList.concat(res.data);
          }
        }
        if (res.data.length < 10) {
          this.moreBtn = false;
        } else {
          this.moreBtn = true;
        }
        this.userList.map((item) => {
          item.moreShow = false;
        });
      });
    },
    getMoreList() {
      //下一页
      this.pageInfo.pageNo++;
      this.getHandList();
    },
    selectStatus() {
      //选择职位
      this.pageInfo.pageNo = 1;
      this.userList = [];
      this.getHandList();
    },
    getMoreWork(data) {
      //展示所有工作经历
      data.moreShow = !data.moreShow;
      this.$forceUpdate();
    },
    pass(data) {
      //取消不合适
      this.$api.pass("post", { id: data.id }).then(() => {
        this.pageInfo.pageNo = 1;
        this.userList = [];
        this.getHandList();
        this.$message({
          message: "操作成功",
          type: "success",
        });
      });
    },
    // handleChangecollapse(val) {
    //   console.log("handleChangeCollapse",val);
    // },
    getdata() {
      this.userList = [
        {
          id: 1,
          position: "安卓开发工程师",
          name: "张珊",
          Flag: false,
          show3: false,
        },
        {
          id: 2,
          position: "前端开发工程师",
          name: "李四",
          Flag: false,
          show3: false,
        },
        {
          id: 3,
          position: "后端开发工程师",
          name: "王五",
          Flag: false,
          show3: false,
        },
        {
          id: 4,
          position: "架构开发工程师",
          name: "孙六",
          Flag: false,
          show3: false,
        },
      ];
      this.userListId = [1, 2, 3, 4]; //初始化多选选择框
    },
    fetchData(page = 1) {
      // this.table_loading = true;
      this.pageInfo.pageNum = page;
      this.formInline.pageNum = this.pageInfo.pageNum;
      this.getdata();
    },
    handleCurrentChange(val) {
      this.fetchData(val);
    },

    // 单个多选框
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.userList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.userList.length;
    },
    // 全选
    handleCheckAllChange(val) {
      // console.log("全选", val, "--", this.userListId);
      this.checkedCities = val ? this.userListId : [];
      this.isIndeterminate = false;
    },
  },
};
</script>

<style>
.transition-box {
  margin-bottom: 10px;
  width: 200px;
  height: 100px;
  border-radius: 4px;
  background-color: #409eff;
  text-align: center;
  color: #fff;
  padding: 40px 20px;
  box-sizing: border-box;
  margin-right: 20px;
}
</style>

<style lang="less" scoped>
/deep/ .el-input__inner {
  border-radius: 0px;
}
.handshake {
  .condition-box {
    background-color: #ffffff;
    padding: 22px 0;
    box-shadow: 0px 1px 29px 17px rgb(0 0 0 / 3%);
    /deep/ .el-input__inner {
      border-radius: 0px;
      border: none;
      background-color: #f3f3f3;
    }

    .header-right {
      /deep/ .el-input__inner {
        border-radius: 0px;
      }
      /deep/ .el-button {
        border-radius: 0px;
        background-color: #00bcff;
        border: none;
      }
    }
  }
}

.listbox {
  .nodata {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #111111;
    padding: 37px 0 20px 0;
    img {
      width: 256px;
      height: 215px;
      margin-bottom: 20px;
    }
  }
  .nextpage {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #999999;
    cursor: pointer;
    margin-top: 15px;
  }
  .list:hover {
    transform: scale(1.02);
    background-color: rgba(0, 0, 0, 0.001);
  }
  .list {
    // padding: 0 30px;
    background-color: #ffffff;
    margin-top: 20px;
    box-shadow: 0px 1px 29px 17px rgb(0 0 0 / 3%);
    padding: 20px 30px;
    display: flex;
    .duoxuan {
      width: 30px;
      .duoxuanbox {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .list-content {
      display: flex;
      flex: 1;
      flex-direction: column;
      .person-info {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #dddddd;
        padding-bottom: 20px;
        .p-picurl {
          margin-right: 20px;
          img {
            width: 50px;
            height: 50px;
          }
        }
        .p-news {
          display: flex;
          flex: 1;
          flex-direction: column;
          line-height: 1.6;
          .pn-name {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .namemain {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              img {
                width: 24px;
                height: 24px;
                margin: 0 18px 0 10px;
              }
              span:last-child {
                background-color: #e9f9ff;
                color: #00bcff;
                padding: 2px 10px;
              }
            }
            .score {
              background-color: #00bcff;
              border-radius: 8px 8px 8px 0;
              color: #ffffff;
              width: 30px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .beizhu {
            color: #666666;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            span {
              margin-right: 48px;
            }
          }
        }
      }
      .transitionbox {
        .t-one:hover {
          cursor: pointer;
        }
        .t-one {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          color: #666666;
          margin-top: 20px;
          img {
            width: 22px;
            height: 20px;
          }
          span {
            margin: 0 30px 0 10px;
          }
        }
      }
      .label-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        .li-left {
          span {
            background-color: #eeeeee;
            padding: 4px 8px;
            margin-right: 10px;
            color: #666666;
          }
        }
        .li-right {
          display: flex;
          align-items: center;
          /deep/ .el-button {
            border-radius: 0px;
            height: 40px;
            margin-left: 30px;
            border: none;
          }
          .yy {
            background-color: #ffa544;
          }
        }
      }
    }

    //
    .p-shu {
      color: #666666;
      line-height: 2;
    }
    .p-info {
      color: #666666;
      line-height: 2;
      .p-tit {
        color: #111;
        font-weight: 600;
      }
    }
    .p-remarks {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 58px;
      div {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        color: #666666;
      }
    }
    .p-operation {
      display: flex;
      div {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        img {
          width: 58px;
          height: 58px;
        }
      }
    }
  }
}
.delete_box {
  line-height: 4;
  .db-img {
    text-align: center;
    img {
      width: 53px;
      height: 57px;
    }
  }
  .db-sure {
    text-align: center;
    color: #111111;
    line-height: 1.5;
  }
  .db-ts {
    text-align: center;
    color: #666666;
    line-height: 1.5;
  }
  .dialog-footer {
    margin-top: 20px;
    /deep/ .el-input__inner {
      border-radius: 0px;
    }
    /deep/ .el-button {
      border-radius: 0px;
    }
    button {
      width: 200px;
      height: 40px;
    }
  }
}

.updown_box {
  line-height: 3;
  padding: 40px 0;
  .ub-img {
    text-align: center;
    img {
      width: 60px;
      height: 60px;
    }
  }
  .ub-text {
    text-align: center;
    color: #111111;
  }
}
</style>
